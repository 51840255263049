<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | GFElite` : `GFElite` }}</template>
  </metainfo>
  <div class="bg-zinc-100 dark:bg-zinc-900 leading-relaxed tracking-wide flex flex-col">
    <NavMenu/>
    <router-view/>
    <FooterMenu/>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import NavMenu from '@/components/NavMenu.vue'
import FooterMenu from '@/components/FooterMenu.vue'

export default {
  name: 'App',
  components: {
    NavMenu,
    FooterMenu
  },
  icons: {
    iconfont: 'mdi',
  },
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  }
}
</script>
