<template>
    <nav id="header" class="fixed lg:absolute w-full z-30 top-0 bg-zinc-100 dark:bg-zinc-900 dark:text-zinc-100 py-1 lg:py-4">
      <div
        class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-2 lg:py-6"
      >
        <div class="pl-4 flex items-center">
          <router-link
            class="inline-flex items-baseline text-zinc-900 dark:text-zinc-100 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
            to="/"
          >
            <img
              class="w-6 h-6 self-center mx-1 lg:w-10 lg:h-10"
              src="@/assets/img/logo.svg"
            />
            GFElite
          </router-link>
        </div>

        <div class="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            class="flex items-center px-3 py-2 border rounded dark:text-zinc-300 dark:border-zinc-300 dark:hover:text-white dark:hover:border-white appearance-none focus:outline-none"
            @click="navToggle"
          >
            <svg
              class="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <div
          class="w-full h-[calc(100vh-56px)] lg:h-auto flex-grow lg:flex lg:items-center lg:w-auto hidden dark:text-zinc-100 p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul class="list-reset lg:flex justify-end flex-1 items-center">
            <li class="mr-3">
              <router-link
                class="inline-block py-2 px-0 lg:px-4 text-zinc-900 dark:text-zinc-100 font-bold no-underline"
                to="/account"
                @click="navToggle"
              >
                Account
              </router-link>
            </li>
            <li class="mr-4">
              <router-link
                class="inline-block text-zinc-600 dark:text-zinc-300 no-underline hover:text-zinc-900 dark:hover:text-white hover:text-underline py-2 px-0 lg:px-4"
                to="/creators"
                @click="navToggle"
              >
                Creators
              </router-link>
            </li>
            <li class="mr-4 mt-3 lg:mt-0">
              <router-link
                id="navAction"
                class="inline-block mx-auto lg:mx-0 hover:underline text-zinc-100 font-extrabold rounded lg:mt-0 py-2 px-6 shadow bg-teal-600 lg:py-3 lg:px-7"
                to="/login"
                @click="navToggle"
              >
                Login
              </router-link>
            </li>
            <li class="mr-3 mt-6 lg:mt-0">
              <label id="switch" class="scale-[0.70] -ml-2 lg:scale-100 switch lg:ml-3">
                <input type="checkbox" @change="toggleTheme" v-model="darkmode" id="darkmode-slider">
                <span class="slider round"></span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</template>

<script>
export default {
  data()
  {
    return {
      darkmode: false,
    }
  },
  mounted(){
    var x = document.body;
    if (this.$cookies.isKey("darkmode")) {
      let dark = this.$cookies.get("darkmode")
      if (dark === "dark") {
        x.classList.add('dark')
        this.darkmode = true
      } else {
        x.classList.remove('dark')
        this.darkmode = false
      }
    } else {
      let dark = window.matchMedia("(prefers-color-scheme: dark)").matches;
      if (dark) {
        x.classList.add('dark')
        this.darkmode = true
      } else {
        x.classList.remove('dark')
        this.darkmode = false
      }
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", function (e) {
        const colorScheme = e.matches ? "dark" : "light";
        if (colorScheme === "dark") {
          x.classList.add('dark')
          this.darkmode = true
        } else {
          x.classList.remove('dark')
          this.darkmode = false
        }
      })
    }
  },
  methods: {
    toggleTheme() {
      var x = document.body;
      if (x.classList.contains('dark')) {
        x.classList.remove('dark')
        this.$cookies.set("darkmode", 'light', -1)
      } else {
        x.classList.add('dark')
        this.$cookies.set("darkmode", 'dark', -1)
      }
    },
    navToggle() {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        var n = document.getElementById("nav-content")
        if (n.classList.contains("hidden")) {
          n.classList.remove("hidden")
        } else {
          n.classList.add("hidden")
        }
      }
    }
  },
}
</script>