<template>
  <div class="flex bg-landing-image bg-center bg-no-repeat bg-cover">
    <div class="backdrop-blur-sm bg-zinc-100/20 dark:bg-zinc-900/40 h-screen w-full">
      <div class="container mx-auto flex h-full items-center px-8 mt-4 lg:mt-14">
        <div class="p-6 lg:p-10 rounded-xl bg-zinc-100/90 dark:bg-zinc-900/90">
          <p class="text-sm sm:text-base md:text-lg font-medium text-teal-400 uppercase mb-0 sm:mb-2 md:mb-6"
          >
            GFElite Community
          </p>
          <h1
            class="text-zinc-900 dark:text-zinc-100 uppercase my-0 sm:my-2 md:my-4 text-4xl sm:text-5xl lg:text-7xl font-black leading-tight break-words"
          >
            Play Solo.
          </h1>
          <h1
            class="text-zinc-900 dark:text-zinc-100 uppercase my-0 sm:my-2 md:my-4 text-4xl sm:text-5xl lg:text-7xl font-black leading-tight break-words"
          >
            Survive Together.
          </h1>
          <p
            class="text-zinc-600 dark:text-zinc-400 text-sm md:text-base lg:text-lg border-l-2 border-zinc-900 dark:border-zinc-100 pl-4 mt-4 md:mt-6 lg:mt:10 pb-2"
          >
            Any time is a good time to play
          </p>
          <p
            class="text-zinc-600 dark:text-zinc-400 text-sm md:text-base lg:text-lg mb-4 md:mb-6 lg:mb-8 border-l-2 border-zinc-900 dark:border-zinc-100 pl-4"
          >
            Play with friends or compete against rivals from around the world
          </p>

          <router-link
            custom
            to="/login"
            v-slot="{ href, navigate }">
          <a
            :href="href"
            @click="navigate"
            class="inline-block mx-auto hover:underline text-zinc-100 font-extrabold rounded py-2 md:py-4 px-6 md:px-8 shadow-lg bg-teal-600 mr-8 mt-2"
          >
            Sign Up
          </a>
          </router-link>
          <router-link
            custom
            to="/creators"
            v-slot="{ href, navigate }">
          <a
            :href="href"
            @click="navigate"
            class="inline-block mx-auto lg:mx-0 hover:underline bg-transparent text-zinc-600 dark:text-zinc-400 font-extrabold py-2 md:py-4 mt-2"
          >
            View Partnered Creators
          </a>
          </router-link>
        </div>
      </div>
    </div>
  </div>

    <section class="bg-zinc-100 py-12 dark:bg-zinc-900">
      <div
        class="container mx-auto flex flex-wrap items-center justify-between pb-12"
      >
        <h2
          class="w-full my-2 text-2xl font-black leading-tight text-center text-zinc-900 dark:text-zinc-100 lg:mt-8"
        >
          Join the Community
        </h2>
        <div class="w-full mb-4">
          <div
            class="h-1 mx-auto bg-orange-300 w-64 opacity-75 my-0 py-0 rounded"
          ></div>
        </div>

        <div
          class="flex flex-1 flex-wrap max-w-4xl mx-auto items-center justify-center text-xl text-gray-500 dark:text-gray-100 font-bold opacity-75"
        >
        <router-link
            custom
            to="/discord"
            v-slot="{ href, navigate }">
          <a
            :href="href"
            @click="navigate"
            target="_blank"
            class="flex mx-auto p-4 md:w-auto items-center hover:text-teal-400"
          >
            <span
              class="text-5xl mr-4 mdi mdi-discord"
            ></span>
            >Discord
          </a>
          </router-link>

          <router-link
            custom
            to="/youtube"
            v-slot="{ href, navigate }">
          <a
            :href="href"
            @click="navigate"
            target="_blank"
            class="flex mx-auto p-4 md:w-auto items-center hover:text-teal-400"
          >
            <span
              class="text-5xl mr-4 mdi mdi-youtube"
            ></span>
            >YouTube
          </a>
          </router-link>

          <router-link
            custom
            to="/twitter"
            v-slot="{ href, navigate }">
          <a
            :href="href"
            @click="navigate"
            target="_blank"
            class="flex mx-auto p-4 md:w-auto items-center hover:text-teal-400"
          >
            <span
              class="text-5xl mr-4 mdi mdi-twitter"
            ></span>
            >Twitter
          </a>
          </router-link>
        </div>
      </div>
    </section>

    <section class="bg-zinc-200 py-8 dark:bg-zinc-800">
      <div class="container mx-auto flex flex-wrap pt-4 pb-12">
        <h2
          class="w-full my-2 text-5xl font-black leading-tight text-center text-zinc-900 dark:text-zinc-100"
        >
          Community services
        </h2>
        <div class="w-full mb-4">
          <div
            class="h-1 mx-auto bg-orange-300 w-64 opacity-75 my-0 py-0 rounded-t"
          ></div>
        </div>

        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="flex-1 bg-amber-100 rounded-t rounded-b-none shadow"
          >
            <a href="#" class="flex flex-wrap no-underline hover:no-underline">
              <p class="w-full text-slate-600 text-xs md:text-sm px-6 mt-6">
                Community Server
              </p>
              <div class="w-full font-bold text-xl text-slate-700 px-6">
                Play on our Game Servers
              </div>
              <p class="text-slate-700 text-base px-6 mb-5">
                Join the exclusive Community Game Servers and play together with your favorite content Creators.
              </p>
            </a>
          </div>
          <div
            class="flex-none mt-auto bg-amber-100 rounded-b rounded-t-none shadow p-6"
          >
            <div class="flex items-center justify-center">
              <router-link
                custom
                to="/games"
                v-slot="{ href, navigate }">
              <button
                :href="href"
                @click="navigate"
                class="mx-auto lg:mx-0 hover:underline bg-slate-800 text-zinc-100 font-extrabold rounded my-6 py-4 px-8 shadow-lg"
              >
                Play Now
              </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="flex-1 bg-teal-100 rounded-t rounded-b-none shadow"
          >
            <a href="#" class="flex flex-wrap no-underline hover:no-underline">
              <p class="w-full text-slate-600 text-xs md:text-sm px-6 mt-6">
                Creator Network
              </p>
              <div class="w-full font-bold text-xl text-slate-700 px-6">
                Discover awesome content
              </div>
              <p class="text-slate-700 text-base px-6 mb-5">
                Looking for entertaining videos or livestreams? Check out our Network of Creators!
              </p>
            </a>
          </div>
          <div
            class="flex-none mt-auto bg-teal-100 rounded-b rounded-t-none shadow p-6"
          >
            <div class="flex items-center justify-center">
              <router-link
                custom
                to="/creators"
                v-slot="{ href, navigate }">
              <button
                :href="href"
                @click="navigate"
                class="mx-auto lg:mx-0 hover:underline bg-slate-800 text-zinc-100 font-extrabold rounded my-6 py-4 px-8 shadow-lg"
              >
                Discover Creators
              </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="flex-1 bg-red-100 rounded-t rounded-b-none shadow"
          >
            <a href="#" class="flex flex-wrap no-underline hover:no-underline">
              <p class="w-full text-slate-600 text-xs md:text-sm px-6 mt-6">
                Community Discord
              </p>
              <div class="w-full font-bold text-xl text-slate-700 px-6">
                Find you next Teammate
              </div>
              <p class="text-slate-700 text-base px-6 mb-5">
                Tired of playing with randoms? Looking for someone to play a Co-op game with? Join our Discord and find your next crew!
              </p>
            </a>
          </div>
          <div
            class="flex-none mt-auto bg-red-100 rounded-b rounded-t-none shadow p-6"
          >
            <div class="flex items-center justify-center">
              <router-link
                custom
                to="/discord"
                v-slot="{ href, navigate }">
              <button
                :href="href"
                @click="navigate"
                class="mx-auto lg:mx-0 hover:underline bg-slate-800 text-zinc-100 font-extrabold rounded my-6 py-4 px-8 shadow-lg"
              >
                Join Today
              </button>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="bg-zinc-100 dark:bg-zinc-900 py-8">
      <div class="container mx-auto flex flex-wrap pt-4 pb-12">
        <h2
          class="w-full my-2 text-5xl font-black leading-tight text-center text-zinc-900 dark:text-zinc-100"
        >
          Creator services
        </h2>
        <div class="w-full mb-4">
          <div
            class="h-1 mx-auto bg-orange-300 w-64 opacity-75 my-0 py-0 rounded"
          ></div>
        </div>

        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="flex-1 bg-red-300 text-slate-700 rounded-t-lg rounded-b-none shadow"
          >
            <div class="relative md:px-5 lg:px-8 py-8 text-3xl font-bold text-center border-b-4 border-b-slate-700">
              <div class="ribbon ribbon-top-left text-zinc-100 after:border-rose-600 before:border-rose-600">
                <span class="bg-rose-500 shadow-md shadow-black/20 font-semibold uppercase">
                  New
                </span>
              </div>
              Game Server
            </div>
            <p class="w-full text-center text-slate-700 text-base px-6 py-4">
              Gain Access to our exclusive Network of
              Game Servers for your Community!
            </p>
            <h1 class="w-full text-center text-slate-700 text-base font-bold border-b border-b-slate-700 py-4">
              Features:
            </h1>
            <ul class="w-full text-center text-sm md:mb-10">
              <li class="border-b border-b-slate-700 py-4">Expand your Community</li>
              <li class="border-b border-b-slate-700 py-4">Play with Viewers</li>
              <li class="border-b border-b-slate-700 py-4">Follower Whitelisting</li>
              <li class="border-b border-b-slate-700 py-4">Collab with other Creators</li>
            </ul>
          </div>
          <div
            class="flex-none mt-auto md:border-t-2 border-slate-700 bg-red-300 rounded-b-lg rounded-t-none shadow p-6"
          >
            <div class="flex items-center justify-center">
              <router-link
                custom
                to="/login"
                v-slot="{ href, navigate }">
              <button
                :href="href"
                @click="navigate"
                class="mx-auto lg:mx-0 hover:underline bg-slate-800 text-zinc-100 font-extrabold rounded my-2 py-4 px-8 shadow-lg"
              >
                Join Now
              </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="flex-1 bg-violet-300 text-slate-700 rounded-t-lg rounded-b-none shadow"
          >
            <div class="relative md:px-5 lg:px-8 py-8 text-3xl font-bold text-center border-b-4 border-b-slate-700">
              Twitch Integration
            </div>
            <p class="w-full text-center text-slate-700 text-base px-6 py-4">
              Bring more interaction to your Stream
              with our powerful Chat Bot
              and easy Alert Overlay!
            </p>
            <h1 class="w-full text-center text-slate-700 text-base font-bold border-b border-b-slate-700 py-4">
              Features:
            </h1>
            <ul class="w-full text-center text-sm md:mb-10">
              <li class="border-b border-b-slate-700 py-4">Custom Commands</li>
              <li class="border-b border-b-slate-700 py-4">Alert Notifications</li>
              <li class="border-b border-b-slate-700 py-4">Timers</li>
              <li class="border-b border-b-slate-700 py-4">Spam Filter</li>
              <li class="border-b border-b-slate-700 py-4">Sound Commands</li>
              <li class="border-b border-b-slate-700 py-4">Twitter Feed</li>
              <li class="border-b border-b-slate-700 py-4">Reminders</li>
              <li class="border-b border-b-slate-700 py-4">Sync with Discord Bot</li>
            </ul>
          </div>
          <div
            class="flex-none mt-auto md:border-t-2 border-slate-700 bg-violet-300 rounded-b-lg rounded-t-none shadow p-6"
          >
            <div class="flex items-center justify-center">
              <router-link
                custom
                to=""
                v-slot="{ href, navigate }">
              <button
                :href="href"
                @click="navigate"
                class="mx-auto lg:mx-0 hover:underline bg-slate-800 text-zinc-100 font-extrabold rounded my-2 py-4 px-8 shadow-lg"
              >
                Coming Soon
              </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="flex-1 bg-blue-300 text-slate-700 rounded-t-lg rounded-b-none shadow"
          >
            <div class="relative md:px-5 lg:px-8 py-8 text-3xl font-bold text-center border-b-4 border-b-slate-700">
              Discord Bot
            </div>
            <p class="w-full text-center text-slate-700 text-base px-6 py-4">
              Extend your Discord Server with helpful
              Tools and Functions!
            </p>
            <h1 class="w-full text-center text-slate-700 text-base font-bold border-b border-b-slate-700 py-4">
              Features:
            </h1>
            <ul class="w-full text-center text-sm md:mb-10">
              <li class="border-b border-b-slate-700 py-4">Custom Commands</li>
              <li class="border-b border-b-slate-700 py-4">Reaciton Roles</li>
              <li class="border-b border-b-slate-700 py-4">Welcome Messages</li>
              <li class="border-b border-b-slate-700 py-4">Spam Filter</li>
              <li class="border-b border-b-slate-700 py-4">Live Notifications</li>
              <li class="border-b border-b-slate-700 py-4">Twitter Feed</li>
              <li class="border-b border-b-slate-700 py-4">Reminders</li>
              <li class="border-b border-b-slate-700 py-4">Sync with Twitch Bot</li>
            </ul>
          </div>
          <div
            class="flex-none mt-auto md:border-t-2 border-slate-700 bg-blue-300 rounded-b-lg rounded-t-none shadow p-6"
          >
            <div class="flex items-center justify-center">
              <router-link
                custom
                to=""
                v-slot="{ href, navigate }">
              <button
                :href="href"
                @click="navigate"
                class="mx-auto lg:mx-0 hover:underline bg-slate-800 text-zinc-100 font-extrabold rounded my-2 py-4 px-8 shadow-lg"
              >
                Coming Soon
              </button>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="bg-zinc-200 dark:bg-zinc-800 py-8 text-center">
      <div class="container mx-auto px-2 pt-4 pb-12 text-gray-800">
        <h2
          class="w-full my-2 text-5xl font-black leading-tight text-center text-zinc-900 dark:text-zinc-100"
        >
          Newsletter
        </h2>
        <div class="w-full mb-4">
          <div
            class="h-1 mx-auto bg-orange-300 w-64 opacity-75 my-0 py-0 rounded"
          ></div>
        </div>

        <h4 class="my-4 text-2xl font-extrabold text-center text-zinc-900 dark:text-zinc-100">
          Be the first to know when new servers become available!
        </h4>

        <router-link
          custom
          to=""
          v-slot="{ href, navigate }">
        <button
          :href="href"
          @click="navigate"
          class="inline-block mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded my-6 py-4 px-8 shadow-lg"
        >
          Get Notified!
        </button>
        </router-link>
      </div>
    </section>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'HomeView',
  setup () {
    useMeta({ title: 'Homepage' })
  }
}
</script>
