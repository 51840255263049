<template>
    <footer class="bg-white dark:bg-zinc-900">
      <div class="container mx-auto mt-8 px-8">
        <div class="w-full flex flex-col md:flex-row py-6">
          <div class="flex-1 mb-6">
            <a
              class="inline-flex items-baseline text-zinc-900 dark:text-zinc-100 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
              href="/"
            >
              <img
              class="w-6 h-6 self-center mx-1 lg:w-10 lg:h-10"
              src="@/assets/img/logo.svg"
            />
              GFElite
            </a>
          </div>

          <div class="flex-1">
            <p class="uppercase font-extrabold text-gray-500 md:mb-6 dark:text-zinc-100">Links</p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <router-link
                  custom
                  to="/faq"
                  v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500 dark:text-zinc-400"
                >
                  FAQ
                </a>
                </router-link>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <router-link
                  custom
                  to="/about"
                  v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500 dark:text-zinc-400"
                >
                  About Us
                </a>
                </router-link>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <router-link
                  custom
                  to="/contact"
                  v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500 dark:text-zinc-400"
                >
                  Contact
                </a>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="flex-1">
            <p class="uppercase font-extrabold text-gray-500 md:mb-6 dark:text-zinc-100">Legal</p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <router-link
                  custom
                  to="/legal"
                  v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500 dark:text-zinc-400"
                >
                  Legal Notice
                </a>
                </router-link>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <router-link
                  custom
                  to="/privacy"
                  v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500 dark:text-zinc-400"
                >
                  Privacy Policy
                </a>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="flex-1">
            <p class="uppercase font-extrabold text-gray-500 md:mb-6 dark:text-zinc-100">Social</p>
            <ul class="list-reset mb-6">
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <router-link
                  custom
                  to="/discord"
                  v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  target="_blank"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500 dark:text-zinc-400"
                >
                  Discord
                </a>
                </router-link>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <router-link
                  custom
                  to="/youtube"
                  v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  target="_blank"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500 dark:text-zinc-400"
                >
                  YouTube
                </a>
                </router-link>
              </li>
              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <router-link
                  custom
                  to="/twitter"
                  v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  target="_blank"
                  class="font-light no-underline hover:underline text-gray-800 hover:text-orange-500 dark:text-zinc-400"
                >
                  Twitter
                </a>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-center text-zinc-600 dark:text-zinc-400 my-5">
          <p
            class="mb-8 text-xs"
          >
            All product and company names and logos are trademarks&trade; or registered&reg; trademarks of their respective holders.<br>
            Use of them does not imply any affiliation with or endorsement by them.
          </p>
          <p
            class="mb-8 text-base"
          >
            &copy; {{new Date().getFullYear()}} - This website is provided by MrNightfloor
          </p>
        </div>
      </div>
      <div id="cookie-consent" class="fixed w-full z-50 bottom-0 bg-zinc-100 dark:bg-zinc-900 dark:text-zinc-100 px-4 py-4">
        <div class="mx-auto items-center text-center text-sm lg:text-base">
          <p class="inline-block text-center text-zinc-900 dark:text-zinc-100 mb-2"
          >
            This website uses cookies to ensure you get the best experience on our website.
            <a
              class="underline"
              href="https://www.cookiesandyou.com/"
            >
              Learn more
            </a>
          </p>
          <button
            class="text-center inline-block mt-2 sm:ml-6 hover:underline text-zinc-100 font-medium rounded py-1 px-4 shadow bg-teal-600"
            @click="cookieConsent"
          >
            Got it!
          </button>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  mounted(){
    if (this.$cookies.isKey("cookieConsent")) {
      var d = document.getElementById('cookie-consent')
      d.classList.add("hidden")
    }
  },
  methods: {
    cookieConsent() {
      var d = document.getElementById('cookie-consent')
      d.classList.add("hidden")
      this.$cookies.set("cookieConsent", true, "1y")
    }
  },
}
</script>