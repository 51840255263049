import { createApp } from 'vue'
import VueCookies from 'vue-cookies'
import { createMetaManager } from 'vue-meta'
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css'
import '@/assets/css/style.css'
import router from '@/router'

const app = createApp(App)
    .use(router)
    .use(VueCookies, { expire: '30d' })
    .use(createMetaManager())

app.mount('#app')