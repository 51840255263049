import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFoundView from '@/views/NotFoundView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/NotFoundView.vue')
    },
    {
        path: '/discord',
        name: 'discord',
        meta: {
            link: "https://discord.gg/FKSMXmBYe4"
        },
        component: () =>
            import ('@/views/ExternalLink.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: NotFoundView
    },
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                top: 0,
                behavior: 'smooth'
            }
        }
    },
    routes
})

export default router