<template>
  <div class="about mt-[56px] lg:mt-[130px] w-full py-8 min-h-[calc(100vh-400px)] bg-zinc-200 dark:bg-zinc-800">
    <div class="text-zinc-900 dark:text-zinc-100 text-center">
      <span
        class="text-7xl md:text-9xl mdi mdi-alert-box-outline"
      ></span>
      <p class="text-4xl md:text-8xl font-black py-4">
        Error 404
      </p>
      <p class="text-lg md:text-2xl font-medium">
        This princess is in another castle!
      </p>
      <div
        class="flex flex-1 flex-wrap max-w-xl mx-auto items-center justify-center text-lg md:text-xl text-gray-700 dark:text-gray-300 font-medium mt-6"
      >
        <a
          class="flex mx-8 md:mx-4 mb-2 md:mb-0 md:w-auto items-center hover:cursor-pointer hover:text-teal-400"
          @click="$router.go(-1)"
        >
          <span class="mr-2 mdi mdi-arrow-left"
          ></span>
          Previous Page
        </a>
        <a
          class="flex mx-8 md:mx-4 mb-2 md:mb-0 md:w-auto items-center hover:cursor-pointer hover:text-teal-400"
          @click="$router.push('/')"
        >
          <span class="mr-2 mdi mdi-home"
          ></span>
          Go Home
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
export default {
  setup() {
    useMeta({ title: 'Not Found' })
  },
}
</script>